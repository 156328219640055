

.navbar-container {
    background-color: black;
    border: 2px solid #ccc;
    border-radius: 15px;
    margin: 10px 25px 0px;
    padding: 10px;
    display: flex;
    z-index: 2;
    position: fixed;
    justify-content: space-between;
    color: white;
  }
  
  .navbar-container.vertical {
    position: fixed;
    text-align: center;
    height: 50vh;
    width: 100px;
    flex-direction: column;
    right: 0px;
    animation: ease throbBorder 5s infinite;
  }
  
  .navbar-container.horizontal {
    position: sticky;
  }
  
  .navbar-item {
    display: inline-block;
    font-size: 18px;
    margin-right: 15px;
    padding: 5px;
  }
  
  .navbar-item:hover {
    background-color: #1a1a1a;
    border-radius: 15px;
  }

  @keyframes throbTwitch {
    0% {
      color: #fff;
      transform: rotate(3deg)
    }
    25% {
      color: #ccc;
      transform: rotate(-3deg)
    }
    75% {
      color: #aaa;
      transform: rotate(0deg)
    }
    100% {
      color: #ccc;
    }
  }

  @keyframes throbBorder {
    0% {
      border-color: #fff;
    }
    25% {
      border-color: #ccc;
    }
    75% {
      border-color: #aaa;
    }
    100% {
      border-color: #ccc;
    }
  }

  @keyframes throbColor {
    0% {
      color: #fff;
    }
    25% {
      color: #ccc;
    }
    75% {
      color: #aaa;
    }
    100% {
      color: #ccc;
    }
  }

  .navbar-item-short-char {
    font-family: "GlitchCity";
    text-align: center;
    font-size: 36px;
    transform: rotateY(0deg) rotateX(0deg);
    animation: ease throbTwitch 3s infinite;
  }

  .navbar-item-short-char.about {
    animation-delay: -0.1s;
  }

  .navbar-item-short-char.blog {
    animation-delay: -0.4s;
  }
  .navbar-item-short-char.creations {
    animation-delay: -0.2s;
  }
  .navbar-item-short-char.drafts {
    animation-delay: -0.5s;
  }
  .navbar-item-short-char.everything {
    animation-delay: -0.3s;
  }
  
  @media only screen and (max-width: 450px) {
    
  
    .navbar-container {
      margin: 10px 4px 0px 2px;
      min-width: 290px;
    }
  
    .navbar-item-name {
      font-size: 12px;
    }
  }