.content-container {
    width: 75vw;
    margin-left: auto;
    margin-right: auto;
}

.inline {
    display: inline-block;
}

.right-bump-1 {
    margin-right: 5px;
}